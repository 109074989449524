import React from 'react';
import Layout from '../components/global/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import PageBanner from '../components/layouts/page-banner';
import { Container, Grid, makeStyles, Typography } from '@material-ui/core';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';
import theme from '../lib/theme';
import CallToAction from '../components/layouts/call-to-action';
import Button from '../components/mui/button';

const usePageStyles = makeStyles(() => ({
  root: {
    '& dl': {
      marginTop: 0,
      '& dt': {
        textTransform: 'lowercase',
        fontFamily: 'Visby CF, sans-serif',
        fontSize: 32,
        fontWeight: 600,
        lineHeight: 1.2,
        color: theme.palette.primary.dark,
        '&:nth-child(n+2)': {
          marginTop: '1.5em',
        },
      },
      '& dd': {
        margin: '10px 0 30px 0',
      },
    },
  },
}));

const FAQ: React.FC = () => {
  const styles = usePageStyles();

  return (
    <Layout>
      <SEO
        title="Frequently Asked Questions"
        description="We've compiled some of the questions we get asked most frequently to help aid in your website processes."
      />
      <PageBanner heading="Frequently Asked Questions" />
      <LightMode>
        <VerticalPadding>
          <Container maxWidth="lg" className={styles.root}>
            <dl>
              <dt>Where are you located?</dt>
              <dd>
                Marvel &amp; Snap
                <br />
                1000 Innovation Drive, Suite 500
                <br />
                Kanata, Ontario K2K 3E7
                <br />
                Canada
              </dd>
              <dt>What are your business hours?</dt>
              <dd>
                Our regular business hours are from 9 a.m. to 5 p.m. Eastern
                Time – Monday to Friday.
              </dd>
              <dt>How can I contact you?</dt>
              <dd>
                Our preferred method is by email; this is how we keep track of
                all our support requests. But you can always give us a call if
                you have questions or just want to chat. We’re also available
                over video conferencing and Slack.
              </dd>
              <dt>What do I do when I want you to update my website?</dt>
              <dd>
                The process is very simple. Send us an email with details on
                what you want updated and we’ll take care of it. Have a look at{' '}
                <Link to="/how-it-works">How it Works</Link> for a great example
                of what you can expect.
              </dd>
              <dt>What is the best way to send you notes/instructions?</dt>
              <dd>
                If there are a lot of details, notes, or instructions to your
                request, you may want to put it all in a Word document and
                include that in your email.
              </dd>
              <dt>Can I call you to explain the changes I want to make?</dt>
              <dd>
                We absolutely encourage you to call us at any time to discuss
                your requirements, to ask questions, or just to brainstorm. We
                love a good brainstorming session!
              </dd>
              <dt>What is your response time?</dt>
              <dd>
                In most cases, we are able to complete your request in less than
                1 hour. However, our official response times depend on the
                package you choose. See our{' '}
                <Link to="/pricing">pricing page</Link> for more information.
              </dd>
              <dt>Do you have a guaranteed turnaround time?</dt>
              <dd>
                In most cases we’re able to address each request within 1 hour,
                but it all depends on the complexity of your request. For that
                reason, we do have guaranteed response times, but we do not
                guarantee turnaround/completion times.
              </dd>
              <dt>I have an emergency update! Can I get it done quickly?</dt>
              <dd>
                We always try to address your request as quickly as possible;
                however, the speed of the work all depends on the complexity of
                the request itself. The best thing to do in an emergency is jump
                on a call with us to discuss in detail.
              </dd>
              <dt>
                What happens if I don’t use all my hours in a given month?
              </dt>
              <dd>
                No problem, you don’t lose your hours. For all Small Team,
                Business and Enterprise customers, your hours will automatically
                get rolled over to the following month. Up to a maximum of 5x
                your monthly hours. Unused hours are calculated based on your
                current package. For example, if you are on the Business (20
                hrs/mo) plan with 60 unused hours and you downgrade to the Small
                Team (10 hrs/mo) plan, you’re unused hours will be reduced to 50
                hours (5x your current monthly hours).
              </dd>
              <dt>Are upfront payments required?</dt>
              <dd>
                Yes. We invoice our clients on the first day of every month for
                that given month.
              </dd>
              <dt>What if I go over my plan&apos;s hourly limit?</dt>
              <dd>
                If you are about to go over your hourly limit in a given month,
                we will notify you. At that point, you will have the option to
                wait to action your request until the next month, upgrade plans,
                or pay for the additional work at our regular hourly rate.
              </dd>
              <dt>Can I change my plan?</dt>
              <dd>
                Absolutely. You can switch between our monthly startup,
                business, or enterprise plans at any point. However, if you have
                any unused hours they may be reduced depending on what plan you
                choose.
              </dd>
              <dt>What payment types are accepted?</dt>
              <dd>
                We accept electronic funds transfer (ETF), e-transfers, and wire
                transfers.
              </dd>
              <dt>What currencies are accepted?</dt>
              <dd>
                Dollar amounts indicated on our site are in Canadian currency.
                Canadian companies are charged G.S.T. or H.S.T. provincial sales
                taxes. Your invoice will state the total tax required by law.
              </dd>
              <dt>What if I want to cancel my plan?</dt>
              <dd>
                That would be very unfortunate and we would be sad to see you
                go. Our Startup, Business, and Enterprise plans are
                month-to-month, so you can cancel your plan at any point by
                giving us 30 days notice. This means you will also forfeit any
                unused hours you have accumulated.
              </dd>
              <dt>Can I include you in our marketing meetings?</dt>
              <dd>
                Nothing would make us happier! We&apos;re available by video
                conference during our regular business hours.
              </dd>
              <dt>Can you help with writing content?</dt>
              <dd>
                We are not content writers, but we have partnered with some very
                good marketing agencies. The best thing would be to give us a
                call and we can discuss what your specific needs are.
              </dd>
            </dl>
          </Container>
        </VerticalPadding>
      </LightMode>
      <CallToAction imageSrc="call-to-action-laptop-chair.jpg">
        <Typography
          variant="h2"
          color="textSecondary"
          gutterBottom={true}
          style={{ maxWidth: '9em' }}
        >
          Get worry-free web operations
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: 24, marginTop: 40, marginBottom: 50 }}
        >
          Develop, launch, manage, and iterate digital marketing assets faster.
          And bring advanced digital experiences to your audience more quickly
          and efficiently.
        </Typography>
        <Grid container spacing={3}>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              to="/contact/"
              style={{ minWidth: 240 }}
            >
              Let&apos;s talk
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              to="/pricing/"
              style={{ minWidth: 240 }}
            >
              Explore pricing
            </Button>
          </Grid>
        </Grid>
      </CallToAction>
    </Layout>
  );
};

export default FAQ;
